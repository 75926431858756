import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/mdxPageLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "160px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "84.375%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAC4iAAAuIgGq4t2SAAACkElEQVQ4y5WTS0vrQBSA+ysFQV24cCHSjV0WfBTMVqmKrnSlCIUoaKL4KhVbaRettFaL0ZKShJY6ec+ZyasRk1xvvXAv3G8xnBnOx5x5nFQYMfpFPA2CYPST8ZwgCOK0VLzqeV4Yhr7vA0Ac/43BYPCd8CVrmjY5OWkYRiaTyefzoig2m83393dJkur1OkKo2+1qmtZqtRqNxtTU1M7ODqU0kYfD4cTERC6Xm56ezmazCwsLPM8vLS2l0+mNjY3FxcVMJrO2tra5uTkzMzM/Pz83N0cISWRFUfb391dWVvb29hiGWV1dPTo6Ojw8PD4+Zhhmd3c3l8tls9l8Pr++vl4oFLa2tjRNS+QwDHu9HsZYkiTLsnq9niAIT09Puq4LgqAoSqfTAQBBEGRZJoQ0Gg3bthO53+9vb28jhJaXl2VZnp2dlSTp4ODg5eXl/v6e5/nz8/Nyufz4+MgwzGAwSKfTz8/PiRwEQbvdxhi3Wi0AiGNVVSmlw+EQIQQAHx8fpmm2220AeHh4AIBEdhzHNE3LsmzbNgwjCAJKqWmasixTSh3HQQhRSgkhruuOP9uXbNv26enp7e0ty7KXl5ccx93c3NTrdY7jzs7OLi4uOI5jWfbk5OT6+no0Gvm+H3+blBtBCAEAjDFEkAgaAQCO46iqalmW53nuGCkcEZfkeZ7v+3Fg2zbGmFLqui6lNN4Q/yQpu1qtXl1d1Wq1UqlUq9WKxWK1Wo3rLJfLPM/f3d1VKhXHceLv/PvMhJBOp6MoiiAIoij2+/3X11dd1yVJkmVZFMW3t7duRHxh3x3yJauqWigUSqUSy7KVSqVYLDabze/W+QfJOwMAQsgwDE3TdF23LGu8K+M4Hv+Ux4/xX3wCveCAAmiJ8OkAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Microsoft Certified Solutions Developer",
          "title": "Microsoft Certified Solutions Developer",
          "src": "/static/098f34f3fbdbf9bd0ffefc2b8774839b/69538/MCSD.png",
          "srcSet": ["/static/098f34f3fbdbf9bd0ffefc2b8774839b/69538/MCSD.png 160w"],
          "sizes": "(max-width: 160px) 100vw, 160px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "160px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "135%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAbCAYAAAB836/YAAAACXBIWXMAAAsTAAALEwEAmpwYAAAD9ElEQVRIx41VWy9rWxTuD/RMPIgfQIhE4kUikQgiIsILHhUpdb8XJarVuhOt1qUNgtIWq61bL/M7+cbZc1k7x945KxmZY84x11jfN27LppS6A5ABYCilDK5aLxQKRrFYFLHq1r2+q9+10dnX1xc+Pj7A5/39HalUCv/3ubi4MHWlFOjQ8Pv9KCsrK2azWVVSUqLsdruKRqMqEAioUCikzs/PldfrVfF4XAWDQZVMJpXf7xcpLS1VXV1dKpVKKfoUhwcHB6ChubkZlZWVaGxsRE1NDdxuN5qamlBbW4u+vj5UV1ejoaFB7P39/aioqEBVVRXq6uqQTCYFpTgMBoNwOByqu7sbo6Oj6OjoQE9PD4aGhrC0tITl5WW0tbVhcHAQnZ2daG1tRW9vLwYGBjA5OQm73Y6bm5tvh4xhLBZTxWIR0WhU4nl5eSmXTk5OkM1mcX19jcfHRznnfe5pp350dITX19dvh3t7e4IwHo+jpaUFXMvLy5HJZDA8PIzT01MwzhMTEwgEAtjc3EQoFEJ7ezteXl5QX18Pn8/37ZCIGGyuh4eH+Pz8FGT5fF6+zHO+yOzTxpXVQCDc7+zsyMdNh7zADNL4/PwsDkiFL+VyOaH88PAgyHmHe50E3mGofiubq6srrK6uqrm5OYyPj0uguS4sLMDlcolOqrOzs3I2PT0tZ1o2NjaEjSDM5/Osen5FKNNAFPwyReu0UYiYZ1zJ7O3tDXyf71FsmUzG4GEul1PWy6TMC1yturZbP85zhoFxFMp0GA6HFcsgkUhIadzd3Ulb8YwlQp2IGMtYLCb6/f29JI17ojSTwkS4XC61srKCxcVFbG1tYWpqCk6nU8rl+PgY8/PzEk/eYaxp58q63d3dNWeBzTAMI51OMxaSZaIlhaenJykVxu+X3RRSY5Y1TTrjHcMwYOMIooHNTbo0kA510qCNtHR8KXrPctEJ4UoRykQyMjKi2LssCXbG2NiY9LC1hBwOh4SAXfGnEWdLp9MGYScSCY4lQcaYsm8ppM49A397eys6hSBIkYysInVIGoVCQelM6VXrtOtHd4V+dDmZdahbz+l0qpmZGckwqeohEIlEZE/Z3t6WccbOWFtbkztnZ2cSIpNyLpeT4RCJRDilxQHnIyUcDkut8ZwvcsKwHjkM9vf3wZYlbYZAIzU75d/eVv8JMrNJsT68px3oFmUeWBFmYbvdbkUa/B14PB5BSJ3omGkWN6lxmOpJ82OW6ZBf8Hg8ihRJi460A44shoFzksKy4ZlOENFaxUTo8/kU64sOvV6v6YDx+un5KTwmQnLnxCYdCpGtr69LAZM640UHRKRR/cmp7dcfX+x/+6FbnfzN4T++VqyWS/MNAgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Microsoft Certified Professional Developer",
          "title": "Microsoft Certified Professional Developer",
          "src": "/static/48940d72c6d5da9b2b5609f90e3e0610/69538/MCPD.png",
          "srcSet": ["/static/48940d72c6d5da9b2b5609f90e3e0610/69538/MCPD.png 160w"],
          "sizes": "(max-width: 160px) 100vw, 160px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p>{`Please click `}<a parentName="p" {...{
        "href": "https://mcp.microsoft.com/authenticate/validatemcp.aspx"
      }}>{`here`}</a>{` to access my Microsoft Certified Professional Transcript.`}</p>
    <p>{`Please input Transcript ID `}<strong parentName="p">{`676431`}</strong>{` and password `}<strong parentName="p">{`pedrolamas`}</strong>{` when required.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      